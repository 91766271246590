import settings from '@/settings'
import axios from 'axios'

/**
 * Admin 後台 學校資訊
 */
class _School {
  /**
   * 指定縣市學校列表
   */
  list(schoolId) {
    return axios.get(settings.api.fullPath + `/ems/school/`)
  }

  /**
   * 學校代碼轉 ID
   */
  codeToId(code) {
    return axios.get(`${settings.api.fullPath}/ems/school/code/${code}`)
  }

  /**
   * 權限變更審核紀錄
   */
  listPermissionChange(schoolId) {
    return axios.get(
      `${settings.api.fullPath}/ems/school/${schoolId}/event/record/permission-change`
    )
  }
}

var School = new _School()
export default School
