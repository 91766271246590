import settings from '@/settings'
import axios from 'axios'

/**
 * OpenADR
 */
class OpenADR {
  /**
   * 查詢不參加日期
   */
  eventOptout(schoolId) {
    return axios.get(
      settings.api.fullPath + `/ems/openadr/event/optout/${schoolId}`
    )
  }

  /**
   * 設定不參加日期
   */
  createEventOptout(params) {
    return axios.post(
      settings.api.fullPath + `/ems/openadr/event/optout`,
      params
    )
  }

  /**
   * 刪除不參加日期
   * todo
   */
  deleteEventOptout(optoutDtId) {
    return axios.delete(
      settings.api.fullPath + `/ems/openadr/event/optout/${optoutDtId}`
    )
  }

  /**
   * ven 列表
   */
  ven() {
    return axios.get(settings.api.fullPath + `/ems/openadr/info`)
  }

  /**
   * ven 註冊
   */
  venRegister(params) {
    return axios.post(settings.api.fullPath + `/ems/openadr/register`, params)
  }

  /**
   * report 註冊
   */
  reportRegister(params) {
    return axios.post(
      settings.api.fullPath + `/ems/openadr/report/register`,
      params
    )
  }

  /**
   * 取得學校ADREVENT紀錄
   */
  getRecord(schoolId, params) {
    return axios.get(
      settings.api.fullPath + `/ems/openadr/event/record/${schoolId}`,
      { params: params }
    )
  }

  /**
   * 停止 ADR 事件
   */
  stopAdrEvent(schoolId) {
    return axios.put(
      settings.api.fullPath + `/ems/openadr/event/stop/${schoolId}`
    )
  }

  /**
   * 查詢 OpenADR 群組
   */
  getOpenADRGroups(schoolId) {
    return axios.get(settings.api.fullPath + `/ems/openadr/groups/${schoolId}`)
  }

  /**
   * 設定 OpenADR 群組
   */
  setOpenADRGroup(schoolId, planType, params) {
    return axios.post(
      settings.api.fullPath + `/ems/openadr/groups/${schoolId}/${planType}`,
      params
    )
  }

  /**
   * 刪除 OpenADR 群組
   */
  deleteOpenADRGroup(schoolId, planType) {
    return axios.delete(
      settings.api.fullPath + `/ems/openadr/groups/${schoolId}/${planType}`
    )
  }
}

var openADR = new OpenADR()
export default openADR
