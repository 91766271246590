<template>
  <div>
    <v-select
      v-model="cityId"
      :items="citys"
      label="選擇縣市"
      hide-details
      class="mb-4"
      solo
      item-text="name"
      item-value="id"
      @change="getSchools"
    ></v-select>

    <v-data-table
      hide-default-footer
      disable-sort
      :headers="headers"
      v-if="filterSchools && filterSchools.length > 0"
      :items="filterSchools"
      :items-per-page="filterSchools.length"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-spacer></v-spacer>
          <div>
            <v-btn color="warning" @click="venALLRegister()" class="mr-4">
              所有學校 VEN 註冊
            </v-btn>
            <v-btn color="warning" @click="reportAllRegister()">
              所有學校 Report 註冊
            </v-btn>
            <div class="caption grey--text text-right">(限可註冊之學校)</div>
          </div>
        </v-toolbar>
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <v-btn
          :disabled="!item.allowVENRegistration"
          color="primary"
          @click="asyncSendVenRegister(item)"
          class="mr-4"
        >
          VEN 註冊
        </v-btn>
        <v-btn
          :disabled="!item.allowReportRegistration"
          color="primary"
          @click="asyncSendReportRegister(item)"
        >
          Report 註冊
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import City from '@/api/admin/City'
import School from '@/api/admin/School'
import OpenADR from '@/api/ems/OpenADR'

export default {
  name: undefined,
  mixins: [],
  components: {},
  props: {},
  data: function () {
    return {
      allSchools: [],
      cityId: null,
      citys: [],
      venList: [],
      headers: [
        {
          text: '學校名稱',
          value: 'name'
        },
        {
          text: 'venId',
          value: 'venId'
        },
        {
          text: 'VEN 註冊狀態',
          value: 'isRegistered'
        },
        {
          text: 'Report 註冊狀態',
          value: 'isRegisteredReport'
        },
        {
          text: '',
          value: 'action'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      processed: 'processed/length'
    }),
    schools() {
      return this.allSchools.map((school) => {
        const OpenADRData = this.venList.find((s) => s.schoolId === school.id)
        school = { ...school, ...OpenADRData }
        school.allowVENRegistration =
          school.venId && (!school.isRegistered || school.isCanceled)
        school.allowReportRegistration =
          school.venId && !school.isRegisteredReport
        return school
      })
    },
    filterSchools() {
      if (!this.cityId) {
        return []
      }
      return this.schools.filter((school) => school.cityId === this.cityId)
    }
  },
  watch: {
    cityId() {
      this.getOpenADR()
      this.getSchools()
    },
    processed(val) {
      if (val === 0) {
        this.getOpenADR()
      }
    }
  },
  created: function () {},
  mounted: function () {
    this.getCity()
    this.getSchools()
    this.getOpenADR()
  },
  destroyed() {},
  methods: {
    ...mapActions({
      setOverlayLoading: 'page/setOverlayLoading',
      showMsg: 'snackbar/show',
      pushFunction: 'processed/pushFunction'
    }),
    getCity() {
      City.list()
        .then((resp) => {
          this.citys = resp.data
          if (this.citys.length === 1) this.cityId = this.citys[0].id
        })
        .catch((error) => {
          console.log(error.response)
        })
    },
    getSchools() {
      if (!this.cityId) {
        return
      }
      School.list(this.cityId)
        .then((resp) => {
          this.allSchools = resp.data
        })
        .catch((error) => {
          console.log(error.response)
        })
    },
    getOpenADR() {
      if (!this.cityId) {
        return
      }
      OpenADR.ven()
        .then((resp) => {
          this.venList = resp.data
        })
        .catch((error) => {
          console.log(error.response)
        })
    },
    venRegister(item) {
      return OpenADR.venRegister({
        venId: item.venId,
        schoolId: item.id
      })
        .then((resp) => {
          this.showMsg({
            message: `${item.name} VEN 註冊設定完成`,
            closable: true,
            time: 3000
          })
        })
        .catch(() => {
          this.showMsg({
            message: `${item.name} VEN 註冊設定失敗`,
            color: 'error',
            closable: true,
            time: 3000
          })
        })
    },
    asyncSendVenRegister(item) {
      this.setOverlayLoading(true)
      this.venRegister(item).finally(() => {
        this.setOverlayLoading(false)
        this.getOpenADR()
      })
    },
    reportRegister(item) {
      return OpenADR.reportRegister({
        venId: item.venId,
        schoolId: item.id
      })
        .then((resp) => {
          this.showMsg({
            message: `${item.name} Report 註冊設定完成`,
            closable: true,
            time: 3000
          })
        })
        .catch(() => {
          this.showMsg({
            color: 'error',
            message: `${item.name} Report 註冊設定失敗`,
            closable: true,
            time: 3000
          })
        })
    },
    asyncSendReportRegister(item) {
      this.setOverlayLoading(true)
      this.reportRegister(item).finally(() => {
        this.setOverlayLoading(false)
        this.getOpenADR()
      })
    },
    venALLRegister() {
      const allowSchools = this.filterSchools.filter(
        (school) => school.allowVENRegistration
      )
      allowSchools.forEach(async (school) => {
        this.pushFunction(() => this.venRegister(school))
      })
    },
    reportAllRegister() {
      const allowSchools = this.filterSchools.filter(
        (school) => school.allowReportRegistration
      )
      allowSchools.forEach(async (school) => {
        this.pushFunction(() => this.reportRegister(school))
      })
    }
  }
}
</script>

<style></style>
